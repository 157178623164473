.portfolio_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portfolio_items {
  background: var(--color-light-blue);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 3px solid transparent;
  transition: var(--transition);
}
.portfolio_items:hover {
  border-color: var(--color-light-blue);
  background: transparent;
}
.portfolio_item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio_items h3 {
  margin: 1.2rem 0 0.2rem;
}
.portfolio_item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}


/* --- Media Queries for (Medium devices) ------- */

@media screen and (max-width: 1024px) {
    .portfolio_container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }   
}



/* --- Media Queries for (Small devices) ------- */

@media screen and (max-width: 600px) {
    .portfolio_container {
         grid-template-columns: 1fr;
         gap: 1rem;
    }
   
}

