@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');


* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style-type: none;
    text-decoration: none;
}

:root {
    /* colors */
    --color-light-blue: #4db5ff;
    --color-dark-blue: #2c2c6c; 
    
    --transition: all 400ms ease;
    /* sizes */ 
    --container-width-lg: 75%;
    --container-width-md: 85%;
    --container-width-sm: 90%;
}

html {
    scroll-behavior: smooth;
}


::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Montserrat', sans-serif;
    background: var(--color-dark-blue);
    color: #fff;
    line-height: 1.5;
}

/* -------------GENERAL STYLES---------------*/

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
  }
  
  h1 {
    font-size: 2.5rem;
  }
  
  section {
    min-height: 100vh;
    padding: 2rem 0;
  }

  section > h2,
  section > h5 {
    text-align: center;
    color: white;
    font-weight: bold;
  }
  
  section > h2 {
    color: var(--color-light-blue);
    margin-bottom: 3rem;
  }
  
  .text-light {
    color: white;
  }
  
  a {
    color: var(--color-light-blue);
    transition: var(--transition);
  }
  
  a:hover {
    color: #fff;
  }
  
  .btn {
    width: max-content;
    display: inline-block;
    color: var(--color-light-blue);
    padding: 0.75rem 1.2rem;
    border-radius: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--color-dark-blue);
    transition: var(--transition);
    border-color: var(--color-light-blue);
  }

  .btn-primary {
    background-color: var(--color-light-blue);
    color: var(--color-dark-blue);
    font-weight: bold;
  }
  
  .btn-primary:hover {
    background-color: white;
  }
  
  .btn:hover {
    background: var(--color-dark-blue);
  }
  
  img {
    display: block;
    width: 100%;
    object-fit: cover;
  }
  
  a {
    color: white;
  }
  
  /* --- Media Queries for (Medium devices) ------- */
  
  @media screen and (max-width: 1024px) {
    .container {
      width: var(--container-width-md);
    }
    .section {
      margin-top: 6rem;
    }
  }
  
  /* --- Media Queries for (Small devices) ------- */
  
  @media screen and (max-width: 600px) {
    .container {
      width: var(--container-width-sm);
    }
    .section {
      margin-top: 4rem;
    }
  }
