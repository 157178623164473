header {
    height: 100vh;
    padding-top: 4rem;
    overflow: hidden;
}


.header_container {
    text-align: center;
    height: 100%;
    position: relative;;
}


/* ====== CTA ====== */
.cta {
   margin-top: 2.5rem; 
   display: flex;
   gap: 1.2rem;
   justify-content: center;
}


img {
    border-radius: 50%;
}
.header_socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;  
}


.header_socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-light-blue);
}

.me {
    background: linear-gradient(var(--color-light-blue), transparent);
    width: 22rem;
    height: 20rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 1.5rem;
    overflow: hidden;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem; 
 }


.scroll_down {
    position: absolute;
    right : -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-size: 0.9rem;
}

/* --- Media Queries for (Medium devices) ------- */

@media screen and (max-width: 1024px) {
    header {
        height: 100vh;
    }    
}



/* --- Media Queries for (Small devices) ------- */

@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }
    .header_socials, .scroll_down {
        display: none;
    }
}

