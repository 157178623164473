.contact-container {
    width: 50%;
    display: grid;
    grid-template-columns: 30% 50%;
    gap: 12%;
}

.contact-options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact-option {
    background: var(--color-light-blue);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact-option:hover {
    background: transparent;
     border-color: var(--color-light-blue);
}


.contact-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}
.contact-option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-light-blue);
    resize: none;
    color: white;
}


/* --- Media Queries for (Medium devices) ------- */

@media screen and (max-width: 1024px) {
   .contact-container {
    grid-template-columns: 1fr;
    gap: 2rem;
   }
}



/* --- Media Queries for (Small devices) ------- */

@media screen and (max-width: 600px) {
    .contact-container {
        width: 90%;
    }
}
