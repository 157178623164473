@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap);
.experience_container {
    display :grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    gap: 2rem;
}

.experience_container > div {
    background: var(--color-light-blue);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
}

.experience_container > div:hover { 
    background: transparent;
    border-color: var(--color-light-blue);
    cursor: default;
}

.experience_container > div h3 {
    text-align: center;
    margin-bottom: 7px;
    color: white;
}

.experience_content { 
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 2rem;
    row-gap: 2rem;
}

.experience_details {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.experience-icons {
    margin-top: 6px;
}


/* --- Media Queries for (Medium devices) ------- */

@media screen and (max-width: 1024px) {
    .experience_container { 
        grid-template-columns: 1fr;
    }
    .experience_container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }
    .experience_content {
        padding: 1rem;

    }
}



/* --- Media Queries for (Small devices) ------- */

@media screen and (max-width: 600px) {
    .experience_container {
        grid-gap: 1rem;
        gap: 1rem;
    }
    .experience_container > div {
        width: 100%; 
        padding: 2rem 1rem;
    }
}
.about_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    grid-gap: 1rem; 
    gap: 1rem; /* Adds some space between all items in the flex container */
}

.about_me {
    width: 80%;
    border-radius: 2rem;
    background: linear-gradient(
        45deg,
        transparent,
        var(--color-dark-blue),
        transparent
    );
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; 
    padding: 1rem; /* Adds some space inside the about_me box */
}

.about_title_small, .about_title_large {
    text-align: center; 
}
.about_title_large {
    margin-bottom: 0rem;
}

.btn-primary {
    padding: 1rem 2rem; /* Adjust as needed */
    border-radius: 0.5rem; /* Adjust as needed */
    color: white; /* Adjust as needed */
    background-color: var(--color-dark-blue); /* Adjust as needed */
    text-decoration: none; /* Removes underline from link */
    font-size: 1rem; /* Adjust as needed */
}

/* --- Media Queries for (Medium devices) ------- */

@media screen and (max-width: 1024px) {
    .about_me {
        width: 70%;
        margin: 2rem auto;
    }
}

/* --- Media Queries for (Small devices) ------- */

@media screen and (max-width: 600px) {
    .about_me {
        width: 85%;
        margin: 1rem auto;
    }
}

nav {
    background: rgba(0,0,0,0.3);
    width:-webkit-max-content;
    width:max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    display: flex;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    bottom: 2rem;
    border-radius: 3rem;
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
}

nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: white;
    font-size: 1.1rem;
}

nav a:hover {
    background : rgba(0,0,0,0.3);
}

nav a.active {
    background: var(--color-light-blue);
    color: white;
}

.portfolio_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2.5rem;
  gap: 2.5rem;
}

.portfolio_items {
  background: var(--color-light-blue);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 3px solid transparent;
  transition: var(--transition);
}
.portfolio_items:hover {
  border-color: var(--color-light-blue);
  background: transparent;
}
.portfolio_item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio_items h3 {
  margin: 1.2rem 0 0.2rem;
}
.portfolio_item-cta {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 1rem;
}


/* --- Media Queries for (Medium devices) ------- */

@media screen and (max-width: 1024px) {
    .portfolio_container {
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.2rem;
        gap: 1.2rem;
    }   
}



/* --- Media Queries for (Small devices) ------- */

@media screen and (max-width: 600px) {
    .portfolio_container {
         grid-template-columns: 1fr;
         grid-gap: 1rem;
         gap: 1rem;
    }
   
}


footer {
    background: var(--color-light-blue);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}

footer a {
    color: var(--color-dark-blue);
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
    margin: 0 auto 3rem;
}


/* --- Media Queries for (Small devices) ------- */

@media screen and (max-width: 600px) {
    .permalinks {
        flex-direction: column;
        grid-gap: 1.5rem;
        gap: 1.5rem;
    }
}




header {
    height: 100vh;
    padding-top: 4rem;
    overflow: hidden;
}


.header_container {
    text-align: center;
    height: 100%;
    position: relative;;
}


/* ====== CTA ====== */
.cta {
   margin-top: 2.5rem; 
   display: flex;
   grid-gap: 1.2rem;
   gap: 1.2rem;
   justify-content: center;
}


img {
    border-radius: 50%;
}
.header_socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;  
}


.header_socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-light-blue);
}

.me {
    background: linear-gradient(var(--color-light-blue), transparent);
    width: 22rem;
    height: 20rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 1.5rem;
    overflow: hidden;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem; 
 }


.scroll_down {
    position: absolute;
    right : -2.3rem;
    bottom: 5rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    font-size: 0.9rem;
}

/* --- Media Queries for (Medium devices) ------- */

@media screen and (max-width: 1024px) {
    header {
        height: 100vh;
    }    
}



/* --- Media Queries for (Small devices) ------- */

@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }
    .header_socials, .scroll_down {
        display: none;
    }
}


.contact-container {
    width: 50%;
    display: grid;
    grid-template-columns: 30% 50%;
    grid-gap: 12%;
    gap: 12%;
}

.contact-options {
    display: flex;
    flex-direction: column;
    grid-gap: 1.2rem;
    gap: 1.2rem;
}

.contact-option {
    background: var(--color-light-blue);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact-option:hover {
    background: transparent;
     border-color: var(--color-light-blue);
}


.contact-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}
.contact-option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

form {
    display: flex;
    flex-direction: column;
    grid-gap: 1.2rem;
    gap: 1.2rem;
}
input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-light-blue);
    resize: none;
    color: white;
}


/* --- Media Queries for (Medium devices) ------- */

@media screen and (max-width: 1024px) {
   .contact-container {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    gap: 2rem;
   }
}



/* --- Media Queries for (Small devices) ------- */

@media screen and (max-width: 600px) {
    .contact-container {
        width: 90%;
    }
}

.education-container {
    display: flex;
    min-height: 31.5rem;
}


.school-history-left {
    background: var(--color-dark-blue);
    padding: 3rem 0px 3rem 1.5rem;
    display: flex;
    align-items: center;
    transition: all 0.2s ease 0s;
    cursor: pointer;
    position: relative;
    flex-wrap: wrap;
}
.school-history-left.selected {
    border-left: 3px solid lightblue;
}

.right {
    padding-left: 3.5rem;
}

.exp-block-wrapper {
    -webkit-animation: 0.2s ease 0s 1 normal none running biCHDc;
            animation: 0.2s ease 0s 1 normal none running biCHDc;
    transition: opacity 0.2s ease 0s;
    position: relative;
    display: flex;
    flex-direction: column;
}
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style-type: none;
    text-decoration: none;
}

:root {
    /* colors */
    --color-light-blue: #4db5ff;
    --color-dark-blue: #2c2c6c; 
    
    --transition: all 400ms ease;
    /* sizes */ 
    --container-width-lg: 75%;
    --container-width-md: 85%;
    --container-width-sm: 90%;
}

html {
    scroll-behavior: smooth;
}


::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Montserrat', sans-serif;
    background: #2c2c6c;
    background: var(--color-dark-blue);
    color: #fff;
    line-height: 1.5;
}

/* -------------GENERAL STYLES---------------*/

.container {
    width: 75%;
    width: var(--container-width-lg);
    margin: 0 auto;
  }
  
  h1 {
    font-size: 2.5rem;
  }
  
  section {
    min-height: 100vh;
    padding: 2rem 0;
  }

  section > h2,
  section > h5 {
    text-align: center;
    color: white;
    font-weight: bold;
  }
  
  section > h2 {
    color: #4db5ff;
    color: var(--color-light-blue);
    margin-bottom: 3rem;
  }
  
  .text-light {
    color: white;
  }
  
  a {
    color: #4db5ff;
    color: var(--color-light-blue);
    transition: all 400ms ease;
    transition: var(--transition);
  }
  
  a:hover {
    color: #fff;
  }
  
  .btn {
    width: -webkit-max-content;
    width: max-content;
    display: inline-block;
    color: #4db5ff;
    color: var(--color-light-blue);
    padding: 0.75rem 1.2rem;
    border-radius: 0.5rem;
    cursor: pointer;
    border: 1px solid #2c2c6c;
    border: 1px solid var(--color-dark-blue);
    transition: all 400ms ease;
    transition: var(--transition);
    border-color: #4db5ff;
    border-color: var(--color-light-blue);
  }

  .btn-primary {
    background-color: #4db5ff;
    background-color: var(--color-light-blue);
    color: #2c2c6c;
    color: var(--color-dark-blue);
    font-weight: bold;
  }
  
  .btn-primary:hover {
    background-color: white;
  }
  
  .btn:hover {
    background: #2c2c6c;
    background: var(--color-dark-blue);
  }
  
  img {
    display: block;
    width: 100%;
    object-fit: cover;
  }
  
  a {
    color: white;
  }
  
  /* --- Media Queries for (Medium devices) ------- */
  
  @media screen and (max-width: 1024px) {
    .container {
      width: 85%;
      width: var(--container-width-md);
    }
    .section {
      margin-top: 6rem;
    }
  }
  
  /* --- Media Queries for (Small devices) ------- */
  
  @media screen and (max-width: 600px) {
    .container {
      width: 90%;
      width: var(--container-width-sm);
    }
    .section {
      margin-top: 4rem;
    }
  }

